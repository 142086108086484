import React, { useState, useEffect } from 'react';
import {
  MessagePlugin,
  Button,
  Card,
  Avatar,
  Popup,
  Collapse,
  Space,
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { HOST_URL } from '@/config/';
import { FileCopyIcon, LoadingIcon, ThumbUp2Icon, UnhappyIcon, RefreshIcon } from 'tdesign-icons-react';
import { Log } from '@/utils/Log';
import axios from 'axios';
import './aiCard.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // 选择主题
// 配置Markdown解析
marked.setOptions({
  breaks: true,
  gfm: true,
  highlight: (code, lang) => {
    if (Prism.languages[lang]) {
      return Prism.highlight(code, Prism.languages[lang], lang);
    }
    return code;
  }
});

const MarkdownRenderer = (content) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [content]);

  // 添加列表样式处理
  const processedHtml = marked.parse(content)
  .replace(/<ol>/g, '<ol style="counter-reset: item; padding-left: 2em;">')
  .replace(/<ul>/g, '<ul style="list-style: none; padding-left: 1em;">');

  return (
    <div
      className="markdown-body"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(processedHtml)
      }}
    />
  );
};

const AICard = (props) => {
  Log.debug('AICard', props);
   const [aiSuggestionStatus, setAiSuggestionStatus] = useState(0);
   // 1代表思考阶段，2代表正式输出阶段,3代表历史数据或者生成完成,0代表等待中,4代表资源紧张，api调用失败
   let answerStatus = 0;
   const [totalSuggestion, setTotalSuggestion] = useState('');
   const [totalThinking, setTotalThinking] = useState('');
   const [history, setHistory] = useState(false);
   const [retry, setRetry] = useState(0);

   const { Panel } = Collapse;
   const changeSuggestionStatus = (suggestion) => {
    Log.debug(suggestion);
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/suggestion/aisuggestion/feedback/${suggestion.tid}`,
      data: suggestion,
    }).then((response) => {
      Log.debug(response);
      Log.debug('修改状态成功');
    });
  };

  const getAISuggestion = () => {
    const xhr = new XMLHttpRequest();
    const url = `${HOST_URL}/api/v1/task/suggestion/aisuggestion/stream?task_id=${props.taskID}&task_type=${props.type}`;

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.withCredentials = true;

    let buffer = '';
    let lastIndex = 0; // 用于跟踪已处理的数据位置


    xhr.send(JSON.stringify({ content: props.prompt }));
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 2) {
        // 初始化状态
        setAiSuggestionStatus(1);
        answerStatus = 1;
      }
      if (xhr.readyState === 3) { // 3=LOADING, 4=DONE
        const newData = xhr.responseText.substring(lastIndex);
        lastIndex = xhr.responseText.length;
        buffer += newData;

        // 处理事件分割
        let eventEnd;
        while ((eventEnd = buffer.indexOf('\n\n')) >= 0) {
          const eventStr = buffer.slice(0, eventEnd);
          buffer = buffer.slice(eventEnd + 2);

          // 提取 data 字段
          const jsonStr = eventStr.replace(/^data: /, '');
          try {
            const event = JSON.parse(jsonStr);
            switch (event.type) {
              case 'thinking':
                setTotalThinking(prev => prev + event.data);
                break;
              case 'suggestion':
                setTotalSuggestion(prev => prev + event.data);
                break;
              case 'status':
                if (event.data === 'thinking_end') {
                  answerStatus = 2;
                  setAiSuggestionStatus(2);
                }
                break;
              default:
                console.warn('未知事件类型:', event.type);
            }
          } catch (err) {
            console.error('解析 JSON 失败:', err);
          }
        }
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        if (answerStatus !== 2) {
          setAiSuggestionStatus(4);
          answerStatus = 4;
          return;
        }
        setAiSuggestionStatus(3);
        answerStatus = 3;
      }
    };

    xhr.onerror = function () {
      setAiSuggestionStatus(4); // 添加错误状态处理
      console.error('请求失败');
    };

    xhr.onabort = function () {
      setAiSuggestionStatus(4);
      console.log('请求被中止');
    };
  };

  // 用于重新生成
  useEffect(() => {
    if (retry == 0) {
      return;
    }
    setAiSuggestionStatus(0);
    answerStatus = 0;
    setTotalSuggestion("");
    setTotalThinking("");
    setHistory(false);
    getAISuggestion();
  }, [retry]);


   useEffect(() => {
    setTotalSuggestion("");
    setTotalThinking("");
    Log.debug('AICard response', answerStatus);
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/task/suggestion/aisuggestion`,
      params: {
        task_id: props.taskID,
      }
    }).then((response) => {
      if (response.code === 0 && response?.data?.suggestion) {
        setTotalSuggestion(response?.data?.suggestion);
        setTotalThinking(response?.data?.analysis);
        setHistory(true);
        setAiSuggestionStatus(3);
        answerStatus = 3;
      }
    }).catch(() => {
      getAISuggestion();
    });
  }, []);

  const cleanMarkdown = (text) => {
    return text
      // 移除代码块标记
      .replace(/```[\s\S]*?```/g, (match) => {
        // 提取代码块内的实际代码（去掉首尾的 ``` 和可能的语言标识）
        const code = match.split('\n').slice(1, -1).join('\n');
        return code;
      })
      // 移除内联代码标记
      .replace(/`([^`]+)`/g, '$1')
      // 移除粗体标记
      .replace(/\*\*([^*]+)\*\*/g, '$1')
      // 移除斜体标记
      .replace(/\*([^*]+)\*/g, '$1')
      // 移除链接，只保留链接文本
      .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
      // 移除标题标记
      .replace(/^#{1,6}\s+(.+)$/gm, '$1')
      // 移除列表标记
      .replace(/^[-*+]\s+/gm, '')
      // 移除数字列表标记
      .replace(/^\d+\.\s+/gm, '')
      // 移除分割线
      .replace(/^-{3,}|_{3,}|\*{3,}$/gm, '')
      // 保留换行符
      .trim();
  };

  return <Card style={{ padding: '5px', marginBottom: '10px' }}
  shadow={false}
  title={history ? "整体优化建议(历史)" : "整体优化建议"}
  description="DeepSeek-R1"
  footer={
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Space direction="vertical">
      <span style={{ marginBottom: '-10px', fontSize: '12px', color: '#999999' }}>当前资源紧张，可能存在卡顿问题，如遇卡顿请等待片刻或稍后重试~</span>
    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-15px' }}>
    <Button  variant="text"
    style={{ padding: '2px 2px' }}
            size="mid"
            onClick = {() => {
              changeSuggestionStatus({
                tid: props.taskID,
                suggestion_status: 10,
                suggestion_tapd: false,
              });
              MessagePlugin.success('感谢您的反馈！', 2 * 1000);
            }}
            icon={<ThumbUp2Icon />}
            >
            有帮助！
          </Button>
          <Button variant="text" size="mid"
          style={{ padding: '2px 2px' }}
          onClick = {() => {
            changeSuggestionStatus({
              tid: props.taskID,
              suggestion_status: 20,
              suggestion_tapd: false,
            });
            MessagePlugin.success('感谢您的反馈！', 2 * 1000);
          }}
          icon={<UnhappyIcon />}
          >
          仍需改进！
          </Button>
          </Space>
      </Space>
    </div>
  }
  actions={
    <>
    <Popup
    content="重新生成"
    trigger="hover">
    <Button variant="text" shape="square" onClick={() => {
      if (aiSuggestionStatus == 1 || aiSuggestionStatus == 2) {
        MessagePlugin.info('当前正在生成中！', 2 * 1000);
      } else {
        setRetry(retry + 1);
      }
    }}>
    <RefreshIcon />
    </Button>
    </Popup>
    <Popup
    content="复制内容"
    trigger="hover">
    <Button variant="text" shape="square" onClick={() => {
      try {
        const cleanedText = cleanMarkdown(totalSuggestion);
        // 创建临时文本区域
        const textArea = document.createElement('textarea');
        textArea.value = cleanedText;
        document.body.appendChild(textArea);
        textArea.select();
        // 尝试使用现代API
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(cleanedText)
            .then(() => {
              MessagePlugin.success('复制成功', 2 * 1000);
            })
            .catch(() => {
              // 如果clipboard API失败，回退到document.execCommand
              document.execCommand('copy');
              MessagePlugin.success('复制成功', 2 * 1000);
            });
        } else {
          // 在不支持clipboard API的环境中使用传统方法
          document.execCommand('copy');
          MessagePlugin.success('复制成功', 2 * 1000);
        }
        // 清理临时文本区域
        document.body.removeChild(textArea);
      } catch (err) {
        MessagePlugin.error('复制失败', 2 * 1000);
      }
    }}>
      <FileCopyIcon />
    </Button>
    </Popup>
    </>
  }
  avatar={<Avatar size="40px" image="https://taiji-hy-1258344706.cos-internal.ap-shanghai.tencentcos.cn/upload_data/local-fa0ce291661d4aa59d9f744cabad9483-deepseek.png"></Avatar>}
  >
  {/* 深度思考部分 */}
  <Collapse
  borderless={true}
  defaultExpandAll={true}
  style={{ marginLeft: '-20px', marginTop: '-25px', marginBottom: '10px' }}
>
  <Panel header={
    aiSuggestionStatus == 1 ? <><LoadingIcon />{"深度思考中..."}</>
    :  aiSuggestionStatus == 2 ? <><LoadingIcon />{"深度思考完成，结果输出中..."}</> :  aiSuggestionStatus == 3 ? "深度思考完成，结果输出完成"
        :  aiSuggestionStatus == 4 ? "请求触发并发限制,请稍后再试" : <><LoadingIcon />{"等待中..."}</>
    }>
    <pre style={{
      whiteSpace: 'pre-wrap',       // 保留换行和空格，允许自动换行
      wordWrap: 'break-word',       // 允许长单词断行
    }}>{totalThinking}</pre>
  </Panel>
</Collapse>
  {/* 正式输出部分 */}
    {MarkdownRenderer(totalSuggestion)}
  </Card>;
};

AICard.propTypes = {
  taskID: PropTypes.string, // 任务id
  prompt: PropTypes.string, // 问题描述
  type: PropTypes.string, // 问题类型
};

export default AICard;
