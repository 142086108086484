import { Space, Link, Loading } from 'tdesign-react';
import { Text } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Log } from '../../../utils/Log';
import { DownloadIcon } from 'tdesign-icons-react';
import axios from 'axios';
import { HOST_URL } from '../../../config';
import qs from 'qs';

const BoltResult = (props) => {
  // Log.debug('TESTResult', props);
  const [loading, setLoading] = useState(false);
  const [binaryUrl, setBinaryUrl] = useState('');
  const args = qs.parse(location.search.slice(1));
  const [taskExpiredTime] = useState(Number(args.taskExpiredTime));
  const [sharedTaskToken] = useState(args.sharedTaskToken);
  const [isSharedURL] = useState(args.isSharedURL);
  const [error, setError] = useState('');
  useEffect(() => {
    setLoading(true);
    // Log.debug('isSharedURL', isSharedURL);
    let requestParams = {};
    if (isSharedURL === 'true') {
      requestParams = {
        cos_files: [`${props.binaryName}`],
        tid: props.taskId,
        expired_time: taskExpiredTime,
        token: sharedTaskToken,
        is_shared_task: '1'
      };
    } else {
      requestParams = {
        cos_files: [`${props.binaryName}`],
        tid: props.taskId,
        is_shared_task: '0'
      };
    }
    Log.debug('requestParams', requestParams);
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/cosfiles`,
      params: requestParams,
    })
    .then((response) => {
      const files = response.data.cos_files;
      if (files && files.length > 0) {
        setBinaryUrl(files[0]);
      }
    })
    .catch((err) => {
      Log.error('Failed to get binary URL:', err);
      setError('获取下载链接失败，请稍后重试');
    })
    .finally(() => {
      setLoading(false);
    });
  }, [props.taskId, isSharedURL]);

  if (loading) {
    return (
      <Space direction="vertical" size={8} style={{ paddingTop: '8px', width: '100%' }}>
        <Loading loading={true} text="获取下载链接中..." />
      </Space>
    );
  }

  return (
    <Space direction="vertical" size={8} style={{ paddingTop: '8px', width: '100%' }}>
      {binaryUrl ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DownloadIcon style={{ marginRight: '8px' }} />
          <Link href={binaryUrl} target="_blank">
            <Text>优化后的二进制文件</Text>
          </Link>
        </div>
      ) : (
        <Text theme="warning">{error || '暂无优化后的二进制文件'}</Text>
      )}
    </Space>
  );
};

BoltResult.propTypes = {
  taskId: PropTypes.string,
  binaryName: PropTypes.string,
};

export default BoltResult;
