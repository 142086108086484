import './style.css';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { HOST_URL } from '../../config';
import { Button, Dialog, Tabs, Card, Textarea, MessagePlugin, Space } from 'tdesign-react';
import TaskSelector from '../../components/taskSelector';
import PropTypes from 'prop-types';
import * as pako from 'pako';
import FlameGraph from '../../components/flamegraph/flamegraph';
import FibonaButton from '../../components/fibona';
import { taskTimeFormat } from '../../utils/DateTime';
import { SwapIcon, TaskIcon } from 'tdesign-icons-react';
import { ColorDifferenceBar, ColorLegend } from '../../components/flamegraph/d3-flamegraph/colorScheme';
import { Log } from '@/utils/Log';
import { getOAName, getUserUid } from '@/utils/Storage';
import GongFengButton from '../../components/gongfengButton';
import qs from 'qs';
import GolangHeapResultCard from '../taskResult/golangHeapResult/golangHeapResultCard';

const TaskCompare = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [dialogVisible1, setDialogVisible1] = useState(false);
  const [dialogVisible2, setDialogVisible2] = useState(false);

  const [shareDialogVisible1, setShareDialogVisible1] = useState(false);
  const [shareDialogVisible2, setShareDialogVisible2] = useState(false);
  const [textAreaText1, setTextAreaText1] = useState('');
  const [textAreaText2, setTextAreaText2] = useState('');

  const [tasks, setTasks] = useState([]);
  const [flameList, setFlameList] = useState({});

  const [selectedTask1, setSelectedTask1] = useState(null);
  const [selectedTask2, setSelectedTask2] = useState(null);
  const [sharedTaskToken1, setSharedTaskToken1] = useState(searchParams.get('sharedTaskToken1'));
  const [sharedTaskToken2, setSharedTaskToken2] = useState(searchParams.get('sharedTaskToken2'));
  const [expiredTime1, setExpiredTime1] = useState(searchParams.get('expiredTime1'));
  const [expiredTime2, setExpiredTime2] = useState(searchParams.get('expiredTime2'));
  const [compareMode, setCompareMode] = useState(2);

  const [finalResultType, setFinalResultType] = useState('');
  const [sourceFilePath, setSourceFilePath] = useState({});

  const updateSourceFilePath = (newData) => {
    setSourceFilePath(prevState => ({
      ...prevState,
      ...newData,
    }));
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks`,
    }).then((res) => {
      const taskRes = res.data.tasks.filter(key => key.analysis_status === 2)
        .sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time));
      setTasks(taskRes);

      if (searchParams.get('sharedTaskToken1') && searchParams.get('expiredTime1')) {
        getSharedTaskInfo(searchParams.get('task1'), searchParams.get('sharedTaskToken1'), searchParams.get('expiredTime1')).then(task => setSelectedTask1(task));
      } else {
        getSharedTaskInfo(searchParams.get('task1')).then(task => setSelectedTask1(task));
      }

      if (searchParams.get('sharedTaskToken2') && searchParams.get('expiredTime2')) {
        getSharedTaskInfo(searchParams.get('task2'), searchParams.get('sharedTaskToken2'), searchParams.get('expiredTime2')).then(task => setSelectedTask2(task));
      } else {
        getSharedTaskInfo(searchParams.get('task2')).then(task => setSelectedTask2(task));
      }
    });
  }, []);

  let tabList = [
    { label: '差分火焰图-在对比任务的火焰图型上查看', value: 2 },
    { label: '差分火焰图-在基准任务的火焰图型上查看', value: 3 },
    { label: '原始火焰图-对比任务', value: 0 },
    { label: '原始火焰图-基准任务', value: 1 },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedTask1?.tid) {
      searchParams.set('task1', selectedTask1?.tid);
      searchParams.set('sharedTaskToken1', sharedTaskToken1);
      searchParams.set('expiredTime1', expiredTime1);
    }
    if (selectedTask2?.tid) {
      searchParams.set('task2', selectedTask2?.tid);
      searchParams.set('sharedTaskToken2', sharedTaskToken2);
      searchParams.set('expiredTime2', expiredTime2);
    }
    const fetchData = async (selectedTask, sharedTaskToken, expiredTime) => {
      let Results;
      if (selectedTask?.profiler_type === 2) {
        Results = '/pprof_result_func_with_filepath.json';
      } else if (selectedTask?.profiler_type === 0) {
        Results = '/drop_perf_func_with_filepath.json';
      }
      const filetask = selectedTask?.tid + Results;
      const requestParams = {
        cos_files: [filetask],
        tid: selectedTask?.tid,
        is_shared_task: '0',
      };
      if (sharedTaskToken) {
        requestParams.token = sharedTaskToken;
        requestParams.expired_time = expiredTime;
        requestParams.is_shared_task = '1';
      }
      try {
        await axios({
          method: 'get',
          url: `${HOST_URL}/api/v1/cosfiles`,
          params: requestParams,
        }).then((response) => {
          const files = response.data.cos_files;
          axios({
            method: 'get',
            url: files[0],
          }).then((response) => {
            if (response) {
              updateSourceFilePath(response);
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    };

    const fetchdif = async (selectedTask1, selectedTask2) =>{
      if (selectedTask1?.tid !== undefined & selectedTask2?.tid !== undefined) {
        const requestParams = {
          task_id: selectedTask1?.tid,
          ref_task_id: selectedTask2?.tid,
        };
        axios({
        method: 'get',
        url: `${HOST_URL}/api/v1/task/flamediff`,
        params: requestParams,
        }).then((response) => {
          if (response !== undefined & response.data !== undefined) {
            const List = {};
            List['base_compare'] = response.data?.base_compare;
            List['base_reference'] = response.data?.base_reference;
            setFlameList(List);
          }
        });
        }
    };

    if (selectedTask1?.tid !== undefined & selectedTask2?.tid !== undefined) {
      fetchData(selectedTask1, sharedTaskToken1, expiredTime1);
      fetchData(selectedTask2, sharedTaskToken2, expiredTime2);
      fetchdif(selectedTask1, selectedTask2);
    }
    history.push({
      search: searchParams.toString(),
    });

    if (taskResultType(selectedTask1) === taskResultType(selectedTask1)) {
      setFinalResultType(taskResultType(selectedTask1));
      if (taskResultType(selectedTask1) === '通用分析结果') {
        tabList = [
          { label: '差分火焰图-在对比任务的火焰图型上查看', value: 2 },
          { label: '差分火焰图-在基准任务的火焰图型上查看', value: 3 },
          { label: '原始火焰图-对比任务', value: 0 },
          { label: '原始火焰图-基准任务', value: 1 },
        ];
      }
    } else {
      setFinalResultType('任务结果不一致');
    }
  }, [selectedTask1, selectedTask2]);

  const handleClose1 = () => {
    setDialogVisible1(false);
  };
  const handleClose2 = () => {
    setDialogVisible2(false);
  };
  const handleShareClose1 = () => {
    setShareDialogVisible1(false);
  };
  const handleShareClose2 = () => {
    setShareDialogVisible2(false);
  };
  const getSharedTaskInfoByUrl = async (url) => {
    try {
      const urlObject = new URL(url);
      const taskID = urlObject.searchParams.get('taskID');
      const sharedTaskToken = urlObject.searchParams.get('sharedTaskToken');
      const expiredTime = urlObject.searchParams.get('taskExpiredTime');
      const result = await getSharedTaskInfo(taskID, sharedTaskToken, expiredTime);
      return {
        result,
        sharedTaskToken,
        expiredTime,
      };
    } catch (e) {
      await MessagePlugin.error(e.toString());
      return null;
    }
  };

  return (
    <div style={{ minHeight: '1100px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: '50%', marginRight: '10px' }}>
          <div style={{ padding: '8px', fontSize: '13px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TaskIcon size={25}/> <p style={{ fontSize: '20px' }}>对比任务</p></div>
            {selectedTask1 ? (
              <>
                <p>任务名: <span>{selectedTask1.name}</span></p>
                <p>任务ID: <span>{selectedTask1.tid}</span></p>
                <p>目标机器: <span>{selectedTask1.target_ip}</span></p>
                <p>创建时间: <span>{taskTimeFormat(selectedTask1.create_time)}</span></p>
                <p>开始时间: <span>{taskTimeFormat(selectedTask1.begin_time)}</span></p>
                <p>结束时间: <span>{taskTimeFormat(selectedTask1.end_time)}</span></p>
                <p>结果类型: <span>{taskResultType(selectedTask1)}</span></p>
              </>
            ) : (
              <p style={{ textAlign: 'center', color: '#888', margin: '90px 0', fontSize: '20px' }}>请选择对比任务</p>
            )}
          </div>
          <Button
            style={{ margin: '5px' }}
            theme="primary"
            onClick={() => {
              setDialogVisible1(true);
            }}
          >
            选取历史任务
          </Button>
          <Button
            style={{ margin: '5px' }}
            variant="text"
            theme="primary"
            onClick={() => {
              setShareDialogVisible1(true);
            }}
          >
            导入分享链接
          </Button>
        </Card>
        <Button className='swap-button' shape="circle" variant="outline" onClick={() => {
          setSelectedTask1(selectedTask2);
          setSelectedTask2(selectedTask1);
          setSharedTaskToken1(sharedTaskToken2);
          setSharedTaskToken2(sharedTaskToken1);
          setExpiredTime1(expiredTime2);
          setExpiredTime2(expiredTime1);
        }}>
          <SwapIcon />
        </Button>
        <Card style={{ width: '50%', marginLeft: '10px' }}>
          <div>
            <div style={{ padding: '8px', fontSize: '13px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TaskIcon size={25}/> <p style={{ fontSize: '20px' }}>基准任务</p></div>
              {selectedTask2 ? (
                <>
                  <p>任务名: <span>{selectedTask2.name}</span></p>
                  <p>任务ID: <span>{selectedTask2.tid}</span></p>
                  <p>目标机器: <span>{selectedTask2.target_ip}</span></p>
                  <p>创建时间: <span>{taskTimeFormat(selectedTask2.create_time)}</span></p>
                  <p>开始时间: <span>{taskTimeFormat(selectedTask2.begin_time)}</span></p>
                  <p>结束时间: <span>{taskTimeFormat(selectedTask2.end_time)}</span></p>
                  <p>结果类型: <span>{taskResultType(selectedTask2)}</span></p>
                </>
              ) : (
                <p style={{ textAlign: 'center', color: '#888', margin: '90px 0', fontSize: '20px' }}>请选择基准任务</p>
              )}
            </div>
          </div>
          <Button
            style={{ margin: '5px' }}
            theme="primary"
            onClick={() => {
              setDialogVisible2(true);
            }}
          >
            选取历史任务
          </Button>
          <Button
            style={{ margin: '5px' }}
            variant="text"
            theme="primary"
            onClick={() => {
              setShareDialogVisible2(true);
            }}
          >
            导入分享链接
          </Button>
        </Card>
        <Dialog
          mode="modeless"
          draggable={true}
          visible={dialogVisible1}
          onClose={handleClose1}
          width="1500px"
          footer={false}
        >
          <TaskSelector
            tasks={tasks}
            onSelectTask={(task) => {
              setSelectedTask1(task);
              handleClose1();
            }}
          />
        </Dialog>
      </div>
      <Dialog
        mode="modeless"
        draggable={true}
        visible={dialogVisible2}
        onClose={handleClose2}
        width="1500px"
        footer={false}
      >
        <TaskSelector
          tasks={tasks}
          onSelectTask={(task) => {
            setSelectedTask2(task);
            handleClose2();
          }}
        />
      </Dialog>
      <Dialog
        mode="modeless"
        header="导入分享链接"
        draggable={true}
        visible={shareDialogVisible1}
        onClose={handleShareClose1}
        cancelBtn={false}
        onConfirm={() => {
          setSelectedTask1(textAreaText1);
          getSharedTaskInfoByUrl(textAreaText1).then(({ result, sharedTaskToken, expiredTime }) => {
            if (result) {
              setSharedTaskToken1(sharedTaskToken);
              setExpiredTime1(expiredTime);
              setSelectedTask1(result);
            }
          });
          setTextAreaText1('');
          setShareDialogVisible1(false);
        }}
      >
        <Textarea placeholder="请输入内容" value={textAreaText1} onChange={value => setTextAreaText1(value)}/>
      </Dialog>
      <Dialog
        mode="modeless"
        header="导入分享链接"
        draggable={true}
        visible={shareDialogVisible2}
        onClose={handleShareClose2}
        cancelBtn={false}
        onConfirm={() => {
          setSelectedTask2(textAreaText2);
          getSharedTaskInfoByUrl(textAreaText2).then(({ result, sharedTaskToken, expiredTime }) => {
            if (result) {
              setSharedTaskToken2(sharedTaskToken);
              setExpiredTime2(expiredTime);
              setSelectedTask2(result);
            }
          });
          setTextAreaText2('');
          setShareDialogVisible2(false);
        }}
      >
        <Textarea placeholder="请输入内容" value={textAreaText2} onChange={value => setTextAreaText2(value)}/>
      </Dialog>
      <Tabs
        style={{ marginTop: '20px' }}
        list={tabList}
        onChange={(value) => {
          setCompareMode(value);
        }}
        value={compareMode}
      >
      </Tabs>
      {finalResultType === '通用分析结果' | finalResultType === 'Java分析结果' | finalResultType === 'Golang内存分析' | finalResultType === '内存泄漏检测' ? <FlameGraphCompare
        task1={selectedTask1}
        task2={selectedTask2}
        compareMode={compareMode}
        sourceFilePath={sourceFilePath}
        sharedTaskToken1={sharedTaskToken1}
        sharedTaskToken2={sharedTaskToken2}
        expiredTime1={expiredTime1}
        expiredTime2={expiredTime2}
        flameList={flameList}
      /> : null}
      {finalResultType === 'Golang内存分析' ? <GolangMemoryCompare
        task1={selectedTask1}
        task2={selectedTask2}
        compareMode={compareMode}
        sharedTaskToken1={sharedTaskToken1}
        sharedTaskToken2={sharedTaskToken2}
        expiredTime1={expiredTime1}
        expiredTime2={expiredTime2}
        flameList={flameList}
      /> : null}
      {finalResultType === '任务结果不一致' ? '任务结果不一致' : null}
    </div>
  );
};

const fetchFlameGraph = async (tid, flamegraphFile, sharedTaskToken, expiredTime) => {
  const requestParams  = { cos_files: [flamegraphFile], tid, is_shared_task: '0' };
  if (sharedTaskToken) {
    requestParams.token = sharedTaskToken;
    requestParams.expired_time = expiredTime;
    requestParams.is_shared_task = '1';
  }
  const response = await axios.get(`${HOST_URL}/api/v1/cosfiles`, {
    params: requestParams,
  });
  if (response.data.cos_files[0]) {
    const data = await axios.get(response.data.cos_files[0], { responseType: 'arraybuffer' });
    try {
      const dataBuf = pako.inflate(data.data, { level: 9 /* python gzip 压缩的默认级别 */ });
      return JSON.parse(new TextDecoder().decode(dataBuf));
    } catch (e) {
      console.log(e);
    }
  }
};

const useFlameGraph = (task, setFlame, setTopFunc, sharedTaskToken, expiredTime) => {
  useEffect(() => {
    const getFlameGraph = async () => {
      if (task?.tid) {
        try {
          const taskData = await getSharedTask(task.tid);
          let flamegraphFile = "";
          if (task.type === 4) {
            flamegraphFile = `${task.tid}/memleak.json.gz`;
          } else {
            flamegraphFile = taskData.taskResult?.['']?.extended_flamegraph_file;
          }
          setTopFunc(taskData.taskResult?.['']?.top_funcs);

          if (flamegraphFile) {
            const data = await fetchFlameGraph(task.tid, flamegraphFile, sharedTaskToken, expiredTime);
            const flameGraph = mergeData(data);
            setFlame(flameGraph);
            console.log('火焰图获取成功');
          } else {
            setFlame(null);
          }
        } catch (error) {
          console.error('Error fetching flame graph:', error);
          setFlame(null);
        }
      } else {
        setFlame(null);
      }
    };
    getFlameGraph();
  }, [task]);
};

const GolangMemoryCompare = (props) => {
  const [taskResult, setTaskResult] = useState(null);
  const [taskData, setTaskData] = useState(null);
  useEffect(() => {
    if (props?.task1 || props?.task2) {
      if (props.compareMode === 0 && props?.task1) {
        getSharedTask(props?.task1?.tid).then((task) => {
          if (task) {
            setTaskData(task);
            setTaskResult(task?.taskResult?.['']);
          }
        });
      }
      if (props.compareMode === 1 && props?.task2) {
        getSharedTask(props?.task2?.tid).then((task) => {
          if (task) {
            setTaskData(task);
            setTaskResult(task?.taskResult?.['']);
          }
        });
      }
    }
  }, [props.compareMode, props?.task1, props?.task2]);

  return (props.compareMode === 0 || props.compareMode === 1)
    ? (taskResult ? <Card>
      <GolangHeapResultCard
      key={''}
      active_threads_json={taskResult?.active_threads_json}
      callgraph_file={taskResult?.callgraph_file}
      flamegraph_file={taskResult?.flamegraph_file}
      goroutine_callgraph_file={taskResult?.goroutine_callgraph_file}
      extended_flamegraph_file={taskResult?.extended_flamegraph_file}
      top_funcs={taskResult?.top_funcs}
      total_toplist={taskResult?.total_toplist}
      goroutine_top_funcs={taskResult?.goroutine_top_funcs}
      total_records={taskResult?.total_records}
      analyse_suggestions={taskData?.suggestions?.['']}
      task_name={taskData?.taskInfo?.name}
      tid={taskData?.taskInfo?.tid}
    ></GolangHeapResultCard>
      </Card> : '加载中')
    : <FlameGraphCompare
      task1={props.task1}
      task2={props.task2}
      compareMode={props.compareMode}
      sourceFilePath={props.sourceFilePath}
      sharedTaskToken1={props.sharedTaskToken1}
      sharedTaskToken2={props.sharedTaskToken2}
      expiredTime1={props.expiredTime1}
      expiredTime2={props.expiredTime2}
    />;
};

const FlameGraphCompare = (props) => {
  const [flame1, setFlame1] = useState(null);
  const [flame2, setFlame2] = useState(null);
  const [topFunc1, setTopFunc1] = useState(null);
  const [topFunc2, setTopFunc2] = useState(null);
  const [suggention, setSuggestion] = useState([]);

  const [showFlame, setShowFlame] = useState(null);
  const [username] = useState(getOAName());
  const [gongFengButtonVisible, setgongFengButtonVisible] = useState(false);

  useFlameGraph(props.task1, setFlame1, setTopFunc1, props.sharedTaskToken1, props.expiredTime1);
  useFlameGraph(props.task2, setFlame2, setTopFunc2, props.sharedTaskToken2, props.expiredTime2);

  const getFibonaresult = (error, uiData, _textData) =>{
    if (error) {
      // 处理错误（没有报错时error为空）...
    } else {
      // // 获取模型输出...
      // const content = data.data[0].meta_data.content;
      const content = String(uiData);
      const index = content.indexOf("函数级别优化建议") + "函数级别优化建议".length; // 找到索引
      let result = content.slice(index); // 截取字符串

      result = result.split("|");
      const commits = [];
      for (let i = 9; i < result.length; i = i + 4) {
        const temp = {};
        const funcName = result[i].trim();
        Log.debug("commits", result[i], (Object.keys(props.sourceFilePath).includes(result[i])), props.sourceFilePath, typeof(result[i]), typeof(props.sourceFilePath));
        if (!(funcName in props.sourceFilePath)) {
          continue;
        }
        temp["path"] = props.sourceFilePath[funcName];
        temp["commits"] = `分析说明：\n${result[i + 1]}\n修改意见：\n${result[i + 2]}\n`;
        commits.push(temp);
      }
      setSuggestion(commits);// 更新AI建议功能
      setgongFengButtonVisible(true);
    }
  };
  useEffect(() => {
    if (flame1 && flame2) {
      switch (props.compareMode) {
        case 0:
          setShowFlame(flame1);
          break;
        case 1:
          setShowFlame(flame2);
          break;
        case 2:
          if (props?.flameList) {
            setShowFlame(props?.flameList["base_compare"]);
          } else {
            setShowFlame(null);
          }
          break;
        case 3:
          if (props?.flameList) {
            setShowFlame(props?.flameList["base_reference"]);
          } else {
            setShowFlame(null);
          }
          break;
      }
    }
  }, [props.compareMode, props.flameList, flame2, flame1]);


  useEffect(() => {
    console.log('火焰图切换', showFlame);
  }, [showFlame]);

  if (!flame1 || !flame2) {
    return <div>
      {!props.task1 && !flame1 ? <div>请选择对比任务</div>
        : (props.task1 && !flame1 ? <div>对比任务不支持火焰图</div> : null)}
      {!props.task2 && !flame2 ? <div>请选择基准任务</div>
        : (props.task2 && !flame2 ? <div>基准任务不支持火焰图</div> : null)}
    </div>;
  }
  if (!showFlame) {
    return '加载中';
  }
  return <Card
    bordered
    size="medium"
    theme="normal"
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <>
      <FibonaButton title="火焰图对比分析" trigger={25}
      callback={getFibonaresult}
      format="csv" data={() => fibonaContent(flame1, flame2, topFunc1, topFunc2)} />
      <div>
      <GongFengButton
        username = {username}
        uid = {getUserUid()}
        suggestion =  {suggention}
        existSuggestion = {gongFengButtonVisible}
        taskid = {qs.parse(location.search.slice(1)).taskID}
        size = "middle"
      ></GongFengButton>
      </div>
      </>
      <Space direction="vertical" >
      <ColorDifferenceBar />
      <ColorLegend />
      </Space>
    </div>
    <FlameGraph
      flamegraphContent={showFlame}
    />
  </Card>;
};


function fibonaContent(flame1, flame2, topFunc1, topFunc2) {
  let content = '以下是您需要分析的差分火焰图数据：\n';
  const diff = calculateDiff(flame1, flame2);
  content += diff.slice(0, 50).map(item => `${item.path} ${item.diff.toFixed(4)}%`)
    .join('\n');
  content += '\n\n以下为对比任务的热点函数：\n';

  const data1 = JSON.parse(topFunc1);
  content += Object.entries(data1).map(([key, value]) => `${key} ${value}`)
    .join('\n');

  content += '\n\n以下为基准任务的热点函数：\n';

  const data2 = JSON.parse(topFunc2);
  content += Object.entries(data2).map(([key, value]) => `${key} ${value}`)
    .join('\n');

  console.log(content);
  return content;
}

function calculateDiff(flame1, flame2) {
  const map1 = convertToMap(flame1);
  const map2 = convertToMap(flame2);
  const allPaths = new Set([...map1.keys(), ...map2.keys()]);
  const diffList = [];

  for (const path of allPaths) {
    const value1 = map1.get(path) || 0;
    const value2 = map2.get(path) || 0;
    const diff = ((value1 / flame1.value) - (value2 / flame2.value)) * 100;
    if (Math.abs(diff) > 0.000001) {
      diffList.push({ path, diff });
    }
  }

  return diffList.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));
}

function convertToMap(flame) {
  const map = new Map();
  const totalValue = flame.value;
  processNode(flame, '', map, totalValue);
  return map;
}

function processNode(node, path, map, totalValue) {
  const newPath = path ? `${path};${node.func}` : node.func;
  let curValue = node.value;

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      processNode(child, newPath, map, totalValue);
      curValue -= child.value;
    });
  }

  map.set(newPath, (map.get(newPath) || 0) + curValue);
}

function taskResultType(task) {
  console.log('task', task);
  if (!task) {
    return '';
  }
  if (task.type === 0) {
    return '通用分析结果';
  }
  if (task.type === 1) {
    return 'Java分析结果';
  }
  if (task.type === 5) {
    return 'Golang内存分析';
  }
  if (task.type === 4) {
    return '内存泄漏检测';
  }
  return '暂不支持的类型';
}

function mergeData(oriData) {
  if (!oriData) return null;

  const data = { ...oriData };
  if (!data.children) return data;

  const stack = [data];
  let n = null;
  while (n = stack.pop()) {
    if (!n.children) {
      continue;
    }

    const mergeMap = {};
    const { children } = n;

    for (let i = 0; i < children.length; ++i) {
      const child = children[i];
      const key = [child.process, child.func].join('\x1E');
      if (!mergeMap[key]) {
        const base = {};
        base.func = child.func;
        base.process = child.process;
        base.module = child.module;
        base.tid = 1; // 将tid设置为定值
        base.value = 0;
        mergeMap[key] = base;
      }

      const base = mergeMap[key];
      base.value += child.value;
      const baseLines = base.module.split('\n');
      const childModules = child.module.split('\n');

      childModules.forEach((childModule) => {
        if (!baseLines.includes(childModule.trim())) {
          base.module = `${base.module.trim()}\n${childModule.trim()}`;
        }
      });

      if (!child.children) continue;

      if (base.children) {
        base.children.push(...child.children);
      } else {
        base.children = [...child.children];
      }

      stack.push(base);
    }

    const newChildren = Object.values(mergeMap);
    if (newChildren.length > 0) {
      n.children = newChildren;
    }
  }

  return data;
}

const TaskResultCache = new Map();
const InFlightRequests = new Map();

const getSharedTask = async (taskID, sharedTaskToken, expiredTime) => {
  if (!taskID) {
    return null;
  }

  // 1. 检查缓存中是否已有结果
  const cached = TaskResultCache.get(taskID);
  if (cached) {
    return cached;
  }

  // 2. 检查是否已有相同 taskID 的请求在进行中
  if (InFlightRequests.has(taskID)) {
    return InFlightRequests.get(taskID);
  }

  // 3. 创建一个新的请求 Promise
  const requestPromise = (async () => {
    try {
      let response;

      // 根据是否是共享任务决定参数
      if (sharedTaskToken && expiredTime) {
        response = await axios.get(`${HOST_URL}/api/v1/task`, {
          params: {
            tid: taskID,
            is_shared_task: '1',
            token: sharedTaskToken,
            expired_time: expiredTime,
          },
        });
      } else {
        response = await axios.get(`${HOST_URL}/api/v1/task`, {
          params: {
            tid: taskID,
            is_shared_task: '0',
          },
        });
      }

      // 如果有数据，则缓存结果
      if (response?.data) {
        TaskResultCache.set(taskID, response.data);
      }

      return response.data;
    } catch (error) {
      // 处理错误消息
      await MessagePlugin.error(error.toString());
      return null;
    } finally {
      // 请求完成后，从 InFlightRequests 中移除
      InFlightRequests.delete(taskID);
    }
  })();

  // 将新的请求 Promise 保存到 InFlightRequests 中
  InFlightRequests.set(taskID, requestPromise);

  return requestPromise;
};

const getSharedTaskInfo = async (taskID, sharedTaskToken, expiredTime) => {
  if (!taskID) {
    return null;
  }

  // 1. 检查缓存中是否已有任务信息
  const cached = TaskResultCache.get(taskID);
  if (cached?.taskInfo) {
    // 返回一个已解决的 Promise，保持返回类型一致
    return Promise.resolve(cached.taskInfo);
  }

  // 2. 检查是否已有相同 taskID 的请求在进行中
  if (InFlightRequests.has(taskID)) {
    // 直接返回正在进行的 Promise
    return InFlightRequests.get(taskID);
  }

  // 3. 创建一个新的请求 Promise
  const requestPromise = (async () => {
    try {
      let response;

      // 根据是否是共享任务决定请求参数
      if (sharedTaskToken && expiredTime) {
        response = await axios.get(`${HOST_URL}/api/v1/task`, {
          params: {
            tid: taskID,
            is_shared_task: '1',
            token: sharedTaskToken,
            expired_time: expiredTime,
          },
        });
      } else {
        response = await axios.get(`${HOST_URL}/api/v1/task`, {
          params: {
            tid: taskID,
            is_shared_task: '0',
          },
        });
      }

      // 如果有数据，则缓存结果
      if (response?.data) {
        TaskResultCache.set(taskID, response.data);
        return response.data.taskInfo;
      }

      // 如果响应中没有数据，返回空对象
      return {};
    } catch (error) {
      // 处理错误消息
      await MessagePlugin.error(error.toString());
      return null;
    } finally {
      // 请求完成后，从 InFlightRequests 中移除
      InFlightRequests.delete(taskID);
    }
  })();

  // 将新的请求 Promise 保存到 InFlightRequests 中
  InFlightRequests.set(taskID, requestPromise);

  return requestPromise;
};

GolangMemoryCompare.propTypes = {
  task1: PropTypes.any,
  task2: PropTypes.any,
  compareMode: PropTypes.any,
  sourceFilePath: PropTypes.any,
  sharedTaskToken1: PropTypes.string,
  sharedTaskToken2: PropTypes.string,
  expiredTime1: PropTypes.string,
  expiredTime2: PropTypes.string,
};

FlameGraphCompare.propTypes = {
  task1: PropTypes.any,
  task2: PropTypes.any,
  compareMode: PropTypes.any,
  sourceFilePath: PropTypes.any,
  sharedTaskToken1: PropTypes.string,
  sharedTaskToken2: PropTypes.string,
  expiredTime1: PropTypes.string,
  expiredTime2: PropTypes.string,
  flameList: PropTypes.any,
};

export default withRouter(TaskCompare);
