// 内存泄漏检测结果展示
import React from 'react';
import {
  Card,
  Layout,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { TaskInfoCard } from '@/components/taskInfo';
import {
  Tabs,
} from 'tdesign-react';
import BoltResultTab from './overview';

const { Body, Content } = Layout;
const BoltResult = (props) => {
  Log.debug('BoltResult', props);
  const { TabPanel } = Tabs;
  return <>
    <Layout className="demo-layout">
      <Body>
        <Content>
          {props.showGobackButton
            ? <Content.Header
              showBackButton
              onBackButtonClick={() => props.goback()}
              title="返回"
            >
            </Content.Header> : ''}
          <Content.Body>
            <TaskInfoCard
              task_info={props.task_info}
              analysis_version={props.analysis_version}
            ></TaskInfoCard>
            <Card>
              <Card.Body
                title="优化结果"
              >
                <Tabs placement={'top'} size={'medium'} defaultValue={1}>
                  <TabPanel value={1} label="下载链接">
                    <BoltResultTab
                      taskId={props.task_info.tid}
                      binaryName={props.task_info.result_file}
                    ></BoltResultTab>
                  </TabPanel>
                </Tabs>
              </Card.Body>
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  </>;
};

BoltResult.propTypes = {
  task_info: PropTypes.object,
  goback: PropTypes.func,
  analysis_version: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

BoltResult.defaultProps = {
  analysis_version: '1.0.0',
};

export default BoltResult;


