// 内存泄漏检测结果展示
import React, { useEffect, useState } from 'react';
import {
  Card,
  Layout,
  Table,
  Button,
  Modal,
  Bubble,
  Icon,
  Text,
  Tabs,
  TabPanel,
} from '@tencent/tea-component';
import { downloadFile } from '@/utils/URL';
import qs from 'qs';
import { JumpIcon } from 'tdesign-icons-react';
import {
  MessagePlugin,
  Tree,
  Link,
  Loading,
  Space,
  Tag,
  Collapse,
  Switch
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { TaskInfoCard } from '@/components/taskInfo';
import './style.css';
import { HOST_URL, MALLOC_FUNCS } from '../../../config';
import axios from 'axios';
import FeedBackButton from '../../../components/feedBackButton';
import Callgraph from '@/pages/taskResult/callgraph';
import FlameGraph from '@/components/flamegraph/flamegraph';
import AICard from '../../../components/aiCard';

const { Body, Content } = Layout;
const { pageable, sortable } = Table.addons;
const { filterable } = Table.addons;
const offsetRegex = /\+0x[0-9a-fA-F]+/g;
const MemleakResult = (props) => {
  Log.debug('MemleakResult', props);

  return <>
      <Layout className="demo-layout">
        <Body>
          <Content>
          {props.showGobackButton
            ? <Content.Header
                showBackButton
                onBackButtonClick={() => props.goback()}
                title="返回"
              >
            </Content.Header> : ''}
          <Content.Body>
            <TaskInfoCard
              task_info = {props.task_info}
              jupyter_url = {props.jupyter_url}
              analysis_version = {props.analysis_version}
            ></TaskInfoCard>
            <Card>
              <Card.Body
              title="任务结果"
              >
                <MemleakResultTab
                  memleak_results={props.memleak_results}
                  duration={props.task_info.request_params.perf_record_duration
                    || props.task_info.request_params.duration}
                  task_name={props.task_info.name}
                  task_tid={props.task_info.tid}
                  flamegraph_file={props.flamegraph_file}
                  callgraph_file={props.callgraph_file}
                  extended_flamegraph_file={props.extended_flamegraph_file}
                  suggestions={props?.analysis_suggestions['']}
                  resfile_createtime={props.task_info?.resfile_create_time}
                ></MemleakResultTab>
              </Card.Body>
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  </>;
};

MemleakResult.propTypes = {
  task_info: PropTypes.object,
  goback: PropTypes.func,
  jupyter_url: PropTypes.string,
  memleak_results: PropTypes.array,
  task_result: PropTypes.array,
  analysis_version: PropTypes.string,
  showGobackButton: PropTypes.bool,
  flamegraph_file: PropTypes.string,
  extended_flamegraph_file: PropTypes.string,
  container_name: PropTypes.string,
  analysis_suggestions: PropTypes.object,
  callgraph_file: PropTypes.string,
};

MemleakResult.defaultProps = {
  jupyter_url: '',
  memleak_results: [],
  analysis_version: '1.0.0',
  callgraph_file: '',
  extended_flamegraph_file: '',
  flamegraph_file: '',
};

function matchCallStack(stack, targetStack) {
  if (!targetStack) {
    return false;
  }
  const stackWithoutOffset = stack.replace(offsetRegex, '');
  return targetStack.includes(stackWithoutOffset);
}

export const MemleakResultTab = (props) => {
  Log.debug('MemleakResultTab', props);
  const [sorts, setSorts] = useState([]);
  const [showCallTreeStatus, setShowCallTreeStatus] = useState(false);
  const [callTreeData, setCalltreeData] = useState([]);
  const [memoryWhiteList, setMemoryWhiteList] = useState([]);
  const [filterRule, setFilterRule] = useState('all');
  const [showExtendedFlamegraph, setShowExtendedFlameGraph] = useState(false);
  const [aiSuggestion, setAiSuggestion] = useState([]);
  const [aiSuggestionStatus, setAiSuggestionStatus] = useState(0);// 0代表不需要更新，1代表正在更新，2代表更新完成，3代表更新失败

  useEffect(() => {
    let content = "";
    const IDs = [];
    for (let index = 0; index < props.suggestions?.length; index++) {
      const suggestion = props.suggestions[index];
      IDs.push(suggestion.id);
      if (suggestion?.ai_suggestion) {
        return ;
      }
      for (const key in suggestion.call_stack) {
        const callStack = key;

        // 将 call_stack 按照 ";" 分割成数组
        const parts = callStack.split(";");

        // 遍历数组，找到第一个包含任何目标字符串的位置
        const ind = parts.findIndex(part => MALLOC_FUNCS.some(target => part.includes(target)));

        // 如果找到了目标字符，则删除其及其后的部分
        if (ind !== -1) {
          parts.splice(ind); // 从第一个出现的位置开始删除
        }
        const result = parts.join(";");
          content += `调用栈${index}: ${result} ${suggestion.call_stack[key]}\n`;
      }
    }
    setAiSuggestionStatus(1);
    const requestParams = {
      content: content,
      ids: IDs,
    };
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/suggestion/aisuggestion/memory`,
      data: JSON.stringify(requestParams),
    }).then((response) => {
      if (!response?.data?.aiSuggestion) {
        return ;
      }
      const resultList = [];
      const result = JSON.parse(response.data.aiSuggestion);
      for (const key in result) {
        resultList.push(JSON.stringify(result[key]));
      };
      setAiSuggestion(resultList);
      setAiSuggestionStatus(2);
    });
  }, []);

  // 设置最大容忍请求时间，超时显示请求错误
  useEffect(() => {
    if (aiSuggestionStatus === 1) {
    const id = setTimeout(() => {
      if (aiSuggestionStatus === 1) {
        setAiSuggestionStatus(3);
      }
    }, 20000); // 20秒
      return () => clearTimeout(id);
    }
  }, [aiSuggestionStatus]);

  function getKeyFunc(callStack, suggestion) {
    let parts = [];
    let num = 0;
    for (const stackStr in callStack) {
      parts = stackStr.split(";");
      num = Math.floor(callStack[stackStr] / 1024 / 1024);
    }
    // 遍历数组，找到第一个包含任何目标字符串的位置
    const ind = parts.findIndex(part => MALLOC_FUNCS.some(target => part.includes(target)));
    // 如果找到了目标字符，则删除其及其后的部分
    if (ind !== -1) {
      parts.splice(ind); // 从第一个出现的位置开始删除
    }
    let result = [];
    if (parts.length < 4) {
      result = parts;
    } else {
      result = parts.slice(-4);
    }
    let resultString = "【函数调用链】：...";
    for (let i = 0; i < result.length - 1; i++) {
      resultString = `${resultString}${result[i]?.replace(/\([^)]*\)/g, '')} ➤ `;
    }
    resultString = resultString + result[result.length - 1]?.replace(/\([^)]*\)/g, '');
    resultString = `${resultString}...`;
    if (num > 0) {
      return <Tag size="large" variant="light"
      style={{ padding: '0px',
      whiteSpace: 'normal',
      height: 'auto',
      wordBreak: 'break-word'}}>
      <span style={{ color: 'black' }}><b>{`${resultString}，`}</b></span><br/>
      <span style={{ color: 'black' }}><b>{`【分配情况】：${suggestion} 分配量达到：`}</b></span>
      <span style={{ color: 'red' }}><b>{num}MB</b></span>
      </Tag>;
    }
    return <div style={{ color: 'grey'}}>{resultString}</div>;
  };
  const panels = [];
  const { Panel } = Collapse;
  if (props.suggestions?.length > 0) {
    for (let index = 0; index < props.suggestions.length; index++) {
      const suggestion = props.suggestions[index];
      if (!suggestion?.call_stack || suggestion?.call_stack === "{}" || suggestion.suggestion === "暂未发现泄漏点" || typeof suggestion?.call_stack !== 'object') {
        continue;
      }
      const aisuggestion = aiSuggestion[index];
      panels.push(<Panel
        header=
       {
        <Space direction="horizontal">{getKeyFunc(suggestion.call_stack, suggestion?.suggestion)}</Space>
       }
        key={index}
        >
        {aiSuggestionStatus === 0 && suggestion?.ai_suggestion ? <>
        <span style={{ fontWeight: 'bold'}}>AI分析：</span><br/>
        <span style={{ fontWeight: 'bold'}}>调用栈涉及功能：</span>
        <span style={{ color: 'grey' }}>{JSON.parse(suggestion?.ai_suggestion)["涉及功能"]}</span><br/>
        <span style={{ fontWeight: 'bold'}}>函数调用分析：</span>
        {JSON.parse(suggestion?.ai_suggestion)["分析"] ? <span style={{ color: 'grey'}}>{JSON.stringify(JSON.parse(suggestion?.ai_suggestion)["分析"])}</span> : <></> }
        <br/>
        <span style={{ fontWeight: 'bold'}}>优化建议：</span>
        <span style={{ color: 'grey' }}>{JSON.parse(suggestion?.ai_suggestion)["优化建议"]}</span>
        </>
        : aiSuggestionStatus === 1 ? <Loading loading={true} text="AI建议生成中..." size="small"></Loading>
        : aiSuggestionStatus === 2 && aisuggestion ? <>
        <span style={{ fontWeight: 'bold'}}>AI分析：</span><br/>
        <span style={{ fontWeight: 'bold'}}>调用栈涉及功能：</span>
        <span style={{ color: 'grey'}}>{JSON.parse(aisuggestion)["涉及功能"]}</span><br/>
        <span style={{ fontWeight: 'bold'}}>函数调用分析：</span>
        {JSON.parse(aisuggestion)["分析"] ? <span style={{ color: 'grey'}}>{JSON.stringify(JSON.parse(aisuggestion)["分析"])}</span> : <></> }
        <br/>
        <span style={{ fontWeight: 'bold'}}>优化建议：</span>
        <span style={{ color: 'grey'}}>{JSON.parse(aisuggestion)["优化建议"]}</span>
        </>
        : <span style={{ color: 'grey' }}>(T ^ T)可能出现网络问题，AI建议生成失败</span>}
        </Panel>);
    }
  }

  const memleakTableOptions = [
    {
      key: 'func',
      header: () => (
        <>
          可能泄漏函数名
          <Bubble content="点击函数名查看调用栈">
            <Icon type="info" />
          </Bubble>
        </>
      ),
      render: result => <>
        <Button type='link'
          onClick={() => {
            setShowCallTreeStatus(true);
            setCalltreeData(result.call_stacks);
          }}
        >{ (String(result.func).length >= 40)
          ? `${String(result.func).slice(0, 40)}...` : String(result.func) }</Button>
        { result.mem_used / props.duration > 10000
          ? <Tag theme="error">疑似泄露较多</Tag> : ''}
        </>,
    },
    {
      key: 'matched',
      header: '标记为无泄漏',
      width: 120,
    },
    {
      key: 'mem_used',
      header: '泄漏内存大小(KB)',
    },
    {
      key: 'call_time',
      header: '调用次数(次)',
    },
    {
      key: 'avg_memleak_speed',
      header: '平均泄漏大小(KB/次调用)',
      render: result => result.mem_used / result.call_time,
    },
  ];


  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/user/memorywhitelist`,
    }).then((response) => {
      if (response?.code === 0 && response.data?.whitelist) {
        setMemoryWhiteList(response.data?.whitelist);
      } else {
        MessagePlugin.error({
          content: '白名单数据请求失败',
        });
      }
    });
  }, []);

  if (props.memleak_results.length === 0) {
    // jeprof
    if (!(props.suggestions.length > 0 && props.suggestions[0].suggestion != "暂未发现泄漏点")) {
      return <>
      <Text theme="success">暂未发现泄漏点</Text>
      </>;
    }
  }

  const avgSorter = (first, second) => {
    const firstspeed = first.mem_used / first.call_time;
    const secondspeed = second.mem_used / second.call_time;
    if (firstspeed > secondspeed) {
      return 1;
    }
    if (firstspeed < secondspeed) {
      return -1;
    }
    return 0;
  };

  const memleakData = props.memleak_results.map((result) => {
    const matched = result.call_stacks.some(target => matchCallStack(target, memoryWhiteList));
    console.log(matched);
    return { ...result, mem_used: result.mem_used / 1024, matched: matched ? '已标记' : '' };
  })
    .filter(a => a.call_time !== 0)
    .sort((first, second) => (first.mem_used > second.mem_used ? -1 : 1));

  let records = [...memleakData.filter(a => a.call_time !== 0)].sort(sortable.comparer(sorts));
  if (filterRule === 'marked') {
    records = records.filter(r => r.matched === '已标记');
  } else if (filterRule === 'unmarked') {
    records = records.filter(r => r.matched === '');
  }

  const tabs = [{
    id: 'table',
    label: '分析建议',
  }];

  if (props.flamegraph_file) {
    tabs.push({
      id: 'flamegraph',
      label: '内存分配火焰图',
    });
  }
  if (props.callgraph_file) {
    tabs.push({
      id: 'callgraph',
      label: '调用图',
    });
  }

  if (props.memleak_results.length === 0 && tabs.length == 0) {
    return <>
    <Text theme="success">暂未发现泄漏点</Text>
  </>;
  }

  const downloadFlamegraphFile = `${props.task_name}-memleak.svg`;

  function getCreateTime(inputString) {
    const parts = inputString.split(' ');
    const partsWithoutLast = parts.slice(0, -1);
    const resultString = partsWithoutLast.join(' ');
    return resultString;
  }

  return <>
   <FeedBackButton taskID={props.task_tid}/>
    <Tabs tabs={tabs} destroyInactiveTabPanel={false}>
    <TabPanel id="flamegraph">
        { props.extended_flamegraph_file === '' ? '' : <Switch size="large" label={['增强火焰图开启', '增强火焰图关闭']}
        value={ showExtendedFlamegraph } defaultValue={false}
        onChange={x => setShowExtendedFlameGraph(x)} />}
        { props.flamegraph_file === '' ? ''
          : <>
            <Link style={{ float: 'right', padding: '0 10px' }}
                  onClick={() => window.open(`/taskCompare?${qs.stringify({ task1: props.task_tid })}`)}
                  theme="primary"
                  suffixIcon={<JumpIcon />}>对比火焰图</Link>
            <Link style={{ float: 'right' }}
                  onClick={() => downloadFile(downloadFlamegraphFile, props.flamegraph_file)}
                  theme="primary">下载火焰图</Link>
          </>
        }
        { !showExtendedFlamegraph ? <div>
            <object width="100%" style={{ margin: '0  auto', display: 'block' }}
                    id="object" data={ props.flamegraph_file }
                    type="image/svg+xml">FlameGraph</object>
        </div>
          : <div>
          <FlameGraph
            flamegraphURL={props.extended_flamegraph_file}
          />
        </div> }
      </TabPanel>
      <TabPanel id="callgraph">
          <div>
            <Callgraph
              callgraphURL={props.callgraph_file}
              defaultNodeCnt={80}
            />
          </div>
        </TabPanel>
        <TabPanel id="table">
          {
            props.memleak_results.length !== 0 ? <><Text theme="warning">发现{props.memleak_results.length}处分配未释放点,共计
            {memleakData.reduce((a, b) => a + b.mem_used, 0).toString()}KB,请关注</Text>
            <Table
              verticalTop
              records={records}
              columns={memleakTableOptions}
              addons={[pageable(),
                sortable({
                  // 这两列支持排序，其中 age 列优先倒序，mail 采用自定义排序方法
                  columns: [
                    {
                      key: 'mem_used',
                      prefer: 'desc',
                    },
                    {
                      key: 'call_time',
                      prefer: 'desc',
                    },
                    {
                      key: 'avg_memleak_speed',
                      prefer: 'desc',
                      sorter: avgSorter,
                    },
                  ],
                  value: sorts,
                  onChange: value => setSorts(value),
                }),
                filterable({
                  type: 'single',
                  column: 'matched',
                  value: filterRule,
                  onChange: value => setFilterRule(value),
                  // 增加 "全部" 选项
                  all: {
                    value: 'all',
                    text: '全部',
                  },
                  // 选项列表
                  options: [
                    { value: 'marked', text: '已标记' },
                    { value: 'unmarked', text: '未标记' },
                  ],
                }),
              ]}
            /></> : <></>
          }
          {props.memleak_results.length !== 0 ? <AICard prompt={props.memleak_results} taskID={props.task_tid} type={"memory"}/>
          : <AICard prompt={props.suggestions} taskID={props.task_tid} type={"memory"}/>}
          {props.resfile_createtime !== undefined && props.resfile_createtime !== ""
          ? <><span style={{ fontWeight: 'bold', fontSize: '15px'}}>采样时间：{getCreateTime(props.resfile_createtime)}</span>
          <span style={{ fontSize: '10px'}}>(此处的采样时间指的是抓取到的最新的jeprof dump数据)</span></> : <></>}
        </TabPanel>
    </Tabs>
    <Modal size="auto" visible={showCallTreeStatus} caption="调用栈" onClose={() => setShowCallTreeStatus(false)}>
        <Modal.Body>
          <CallStackGraph
            call_stacks={callTreeData}
            memory_whitelists={memoryWhiteList}
            onWhiteListChanged={(event) => {
              setMemoryWhiteList(event);
            }}
          ></CallStackGraph>
        </Modal.Body>
      </Modal>
  </>;
};

MemleakResultTab.propTypes = {
  memleak_results: PropTypes.array,
  duration: PropTypes.number,
  flamegraph_file: PropTypes.string,
  extended_flamegraph_file: PropTypes.string,
  suggestions: PropTypes.array,
  callgraph_file: PropTypes.string,
  task_name: PropTypes.string,
  task_tid: PropTypes.string,
  resfile_createtime: PropTypes.string,
};

MemleakResultTab.defaultProps = {
  task_name: "内存分析",
  task_tid: "",
  duration: 1,
  flamegraph_file: "",
  extended_flamegraph_file: "",
  callgraph_file: "",
  memleak_results: [],
};

// 展示调用图组件
export const CallStackGraph = (props) => {
  Log.debug('callStackGraph', props);

  const toTreeDataItem = (label) => {
    const replaced = label.replace(offsetRegex, '');
    const marked = props.memory_whitelists?.includes(replaced);
    const requestParams = {
      operation: marked ? 'del' : 'add',
      function: replaced,
    };
    return (
      <div className="hover-reveal">
        <span className={marked ? 'marked-text' : ''}>{label}</span>
        <span className="hidden-text" onClick={() => {
          axios({
            method: 'post',
            url: `${HOST_URL}/api/v1/user/memorywhitelist`,
            data: requestParams,
          }).then((response) => {
            if (response?.code === 0) {
              props.onWhiteListChanged(response.data?.whitelist);
            } else {
              MessagePlugin.error({
                content: '白名单数据请求失败',
              });
            }
          });
        }}>{marked ? '取消标记' : '标记为无泄漏'}</span>
      </div>
    );
  };

  const changeArrayToTreeData = (data) => {
    const treeData = [{
      value: 0,
      label: toTreeDataItem(data[0]),
      children: [],
    }];
    let temp = treeData[0].children;
    for (let i = 1; i <= data.length - 2; ++i) {
      temp.push({
        value: i,
        label: toTreeDataItem(data[i]),
        children: [],
      });
      temp = temp[0].children;
    }
    temp.push({
      value: data.length - 1,
      label: toTreeDataItem(data[data.length - 1]),
    });
    return treeData;
  };

  return <>
    <Tree data={changeArrayToTreeData(props.call_stacks)} line={true} expandAll></Tree>
  </>;
};

CallStackGraph.propTypes = {
  call_stacks: PropTypes.object,
  memory_whitelists: PropTypes.array,
  onWhiteListChanged: PropTypes.func,
};

CallStackGraph.defaultProps = {
  call_stacks: [],
  memory_whitelists: [],
  onWhiteListChanged: () => { },
};

export default MemleakResult;
